import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">



<path d="M2680 7471 c0 -4 -13 -22 -29 -38 -57 -60 -60 -137 -64 -1720 -2
-1029 -2 -1033 38 -1033 8 0 15 -5 15 -11 0 -8 207 -10 768 -7 l767 4 62 29
c34 17 63 33 63 38 0 4 -11 25 -25 46 -47 75 -150 337 -185 468 l-11 43 -367
2 -367 3 -3 1093 -2 1092 -330 0 c-182 0 -330 -4 -330 -9z"/>
<path d="M4190 7445 c-27 -43 -46 -134 -50 -243 -2 -42 -4 -471 -4 -952 0
-752 2 -883 15 -935 20 -78 36 -87 130 -69 109 21 320 130 405 210 46 44 101
119 124 171 47 107 54 195 55 693 1 530 -4 497 91 599 65 70 178 135 280 161
102 26 309 36 434 21 131 -16 241 -53 382 -128 66 -36 122 -63 124 -60 4 4
-63 92 -152 202 -145 178 -322 284 -562 337 -92 21 -128 22 -673 26 l-576 3
-23 -36z"/>
<path d="M6556 7470 c-56 -10 -198 -55 -240 -77 -39 -20 -93 -60 -151 -111
l-48 -42 52 -59 c28 -33 51 -62 51 -65 0 -3 10 -16 23 -30 13 -14 32 -43 43
-65 10 -23 22 -41 27 -41 4 0 7 -5 7 -10 0 -6 12 -29 27 -53 15 -23 36 -57 46
-76 22 -41 20 -44 -70 -92 -35 -18 -63 -37 -63 -42 0 -6 20 -60 44 -121 50
-127 88 -280 110 -441 l14 -109 69 -66 c38 -36 91 -81 119 -99 27 -19 51 -38
54 -42 3 -3 32 -24 65 -45 230 -149 310 -245 306 -369 -5 -137 -160 -203 -412
-176 -84 9 -119 18 -179 47 -41 19 -89 46 -107 60 -17 13 -36 24 -42 24 -5 0
-17 -24 -27 -52 -46 -142 -141 -295 -251 -405 -40 -40 -73 -80 -73 -89 0 -20
38 -74 52 -74 5 0 24 -6 41 -13 47 -20 94 -34 172 -49 98 -18 697 -18 775 1
492 119 764 382 787 762 7 122 -14 221 -70 331 -80 157 -238 277 -617 469
-413 209 -547 351 -495 526 19 63 52 105 112 140 42 25 49 26 146 20 178 -10
276 -63 352 -188 48 -79 68 -87 136 -55 30 13 88 43 129 66 41 23 107 55 145
71 39 17 79 34 90 39 11 4 56 21 100 36 44 15 84 31 89 36 12 9 -20 70 -77
148 -136 184 -301 295 -533 357 -94 25 -112 26 -389 29 -159 1 -312 -1 -339
-6z"/>
<path d="M8018 7406 c-21 -8 -49 -21 -64 -31 -29 -19 -63 -87 -64 -125 0 -34
33 -98 62 -119 87 -63 189 -51 247 30 92 127 -34 297 -181 245z m126 -47 c57
-45 71 -117 35 -176 -25 -40 -53 -53 -117 -53 -60 0 -108 29 -128 77 -27 63
-2 119 72 161 33 19 108 14 138 -9z"/>
<path d="M8013 7340 c-12 -5 -24 -17 -27 -27 -14 -48 -16 -91 -7 -116 10 -25
14 -27 74 -27 86 0 101 12 101 85 1 48 -3 60 -23 76 -25 20 -80 24 -118 9z
m105 -22 c7 -7 12 -35 12 -64 0 -52 -9 -64 -46 -64 -16 0 -20 65 -4 75 13 8 1
35 -16 35 -16 0 -18 -9 -15 -62 2 -27 -2 -40 -14 -44 -21 -8 -28 0 -28 34 0
49 13 83 36 92 32 13 61 12 75 -2z"/>
<path d="M5272 6905 c-85 -22 -251 -100 -267 -125 -13 -21 9 -57 51 -83 25
-15 51 -27 59 -27 8 0 15 -4 15 -10 0 -5 7 -10 15 -10 16 0 146 -65 178 -90
81 -61 178 -180 198 -245 44 -136 52 -186 55 -335 3 -176 -10 -259 -56 -349
-52 -103 -173 -203 -301 -247 -45 -16 -83 -19 -223 -19 l-170 0 -30 -51 c-85
-139 -208 -278 -331 -373 -90 -69 -95 -74 -95 -96 1 -19 32 -65 45 -65 3 0 34
-13 69 -30 90 -42 136 -50 320 -56 171 -6 369 10 471 40 11 3 36 9 55 14 36 9
281 112 290 121 9 10 69 51 75 51 11 0 92 56 160 111 185 149 291 298 359 507
43 133 56 232 56 435 0 213 -11 311 -47 417 -48 142 -184 378 -245 423 -57 42
-190 81 -348 102 -153 21 -249 18 -358 -10z"/>
<path d="M7785 4941 c-59 -36 -78 -67 -83 -130 -8 -109 61 -186 168 -186 71 0
121 31 151 93 29 58 23 141 -12 178 -58 62 -163 83 -224 45z m138 -25 c75 -32
105 -109 71 -181 -66 -139 -279 -72 -261 82 10 89 102 137 190 99z"/>
<path d="M7800 4881 c-15 -5 -23 -14 -21 -22 2 -8 3 -39 2 -71 -2 -48 1 -58
21 -72 33 -24 110 -21 133 4 27 29 33 130 10 153 -17 18 -96 22 -145 8z m124
-73 c0 -59 -16 -78 -64 -78 -42 0 -50 11 -50 65 0 59 7 66 64 63 l51 -3 -1
-47z"/>
<path d="M7840 4820 c-7 -14 -7 -20 0 -20 5 0 10 -11 10 -25 0 -14 4 -25 9
-25 12 0 21 26 21 61 0 34 -24 40 -40 9z"/>
<path d="M3106 4055 c-43 -40 -33 -79 25 -95 44 -13 83 12 87 58 6 58 -64 82
-112 37z"/>
<path d="M3354 4058 c-46 -54 -47 -62 -50 -327 -4 -289 -1 -306 62 -336 56
-27 154 -25 219 5 28 12 52 25 55 29 3 3 24 19 47 35 23 15 57 51 75 79 29 46
33 61 37 134 3 71 0 89 -22 136 -20 43 -35 61 -71 82 -41 24 -54 27 -109 22
-34 -3 -72 -8 -84 -12 -23 -7 -23 -6 -23 84 l0 91 -58 0 c-48 0 -62 -4 -78
-22z m204 -317 c49 -62 61 -139 27 -171 -23 -22 -89 -43 -99 -32 -12 11 -6
218 6 230 13 13 44 0 66 -27z"/>
<path d="M2630 4040 c-16 -14 -39 -48 -51 -75 -22 -47 -23 -63 -23 -266 0
-226 7 -264 49 -299 57 -47 336 -21 377 35 4 6 8 36 8 68 l0 57 -115 0 -115 0
0 244 c0 172 -3 247 -11 252 -7 4 -30 7 -51 8 -30 1 -47 -6 -68 -24z"/>
<path d="M6290 4048 c0 -6 -9 -21 -19 -32 -11 -12 -24 -39 -30 -61 -15 -56 -9
-492 7 -522 23 -42 113 -54 216 -27 149 39 245 105 292 203 23 46 25 195 5
265 -21 73 -99 147 -178 169 -72 21 -293 24 -293 5z m216 -164 c79 -52 96 -82
102 -171 4 -70 3 -76 -23 -103 -37 -39 -73 -52 -130 -48 l-48 3 0 105 c0 245
1 250 24 250 11 0 45 -16 75 -36z"/>
<path d="M5082 4008 c-6 -6 -12 -18 -12 -25 0 -8 -20 -33 -45 -57 -45 -42 -45
-43 -47 -112 -2 -88 29 -319 49 -361 30 -64 135 -82 202 -34 27 20 31 28 31
72 0 44 -2 49 -23 49 -12 0 -33 7 -45 16 -21 14 -22 23 -22 130 0 112 0 114
23 114 49 0 66 11 73 47 9 49 -1 55 -89 62 -5 1 -7 25 -6 54 l3 52 -39 3 c-23
2 -46 -3 -53 -10z"/>
<path d="M7400 3982 c-25 -12 -36 -25 -38 -44 -2 -19 -11 -29 -33 -37 l-30
-10 6 -168 c7 -226 11 -249 50 -291 27 -30 39 -36 88 -40 32 -2 57 0 57 4 0 5
16 18 35 28 25 14 36 28 41 51 10 56 3 69 -41 75 l-40 5 -3 113 -3 113 30 12
c24 9 30 17 34 51 5 52 2 56 -34 56 -28 0 -29 1 -29 50 0 48 -1 50 -27 50 -16
0 -44 -8 -63 -18z"/>
<path d="M4787 3916 c-20 -8 -44 -19 -51 -25 -11 -10 -16 -8 -21 8 -6 19 -15
21 -76 21 -57 0 -71 -3 -76 -17 -4 -10 -12 -22 -19 -28 -19 -15 -33 -80 -39
-180 -3 -50 -8 -110 -10 -135 -4 -44 10 -113 28 -137 13 -18 77 -16 121 2 l36
15 0 92 c0 128 22 202 69 234 20 13 42 24 51 24 8 0 30 9 48 20 29 18 32 24
32 70 0 48 -1 50 -27 50 -16 -1 -45 -7 -66 -14z"/>
<path d="M4087 3909 c-51 -12 -107 -42 -135 -72 -29 -30 -78 -150 -87 -211
-15 -96 34 -174 143 -225 26 -12 53 -26 61 -30 29 -15 128 8 189 44 34 19 68
42 76 51 16 16 23 104 9 104 -5 0 -32 -9 -61 -20 -61 -23 -101 -20 -153 13
l-34 21 25 7 c14 4 37 7 53 8 36 1 117 40 125 60 3 8 13 23 21 33 24 27 29 68
15 116 -26 90 -124 130 -247 101z m43 -154 c35 -18 38 -30 11 -45 -21 -10
-101 -14 -101 -4 0 11 23 49 34 56 16 11 23 10 56 -7z"/>
<path d="M5392 3895 c-7 -14 -12 -43 -12 -64 0 -39 32 -160 50 -193 6 -10 10
-25 10 -33 0 -9 9 -42 20 -74 11 -33 23 -84 26 -115 6 -50 4 -57 -16 -74 -26
-21 -35 -72 -16 -97 10 -14 21 -16 54 -10 94 16 128 62 197 263 24 70 65 185
90 255 25 71 45 137 45 148 0 17 -7 19 -64 19 l-64 0 -20 -47 c-11 -27 -27
-75 -36 -108 -10 -33 -20 -68 -22 -78 -10 -34 -30 0 -69 118 l-39 115 -61 0
c-56 0 -63 -2 -73 -25z"/>
<path d="M6952 3875 c-55 -48 -55 -79 1 -83 20 -2 48 -9 62 -16 24 -13 23 -13
-29 -25 -81 -18 -126 -56 -154 -128 -20 -49 -22 -68 -16 -109 5 -27 8 -54 8
-60 -1 -7 17 -22 40 -35 38 -22 50 -24 160 -21 101 2 125 6 154 24 l34 21 -4
186 c-3 163 -5 190 -22 214 -26 38 -68 58 -135 64 -53 5 -58 3 -99 -32z m78
-240 c17 -9 30 -22 30 -29 0 -8 5 -16 11 -18 9 -3 9 -10 0 -26 -13 -24 -59
-30 -79 -10 -13 13 -16 56 -6 82 8 20 8 20 44 1z"/>
<path d="M7733 3885 c-42 -21 -53 -32 -53 -51 0 -13 3 -24 8 -25 68 -4 98 -10
120 -25 25 -17 25 -19 7 -22 -125 -23 -180 -78 -204 -206 -9 -51 10 -106 51
-142 30 -28 233 -23 297 7 l44 20 -7 172 c-4 95 -12 186 -17 203 -14 44 -71
81 -139 89 -47 5 -62 2 -107 -20z m97 -250 c6 -8 9 -18 6 -24 -4 -5 1 -17 10
-26 16 -15 15 -18 -5 -38 -20 -20 -23 -21 -49 -7 -33 19 -41 63 -16 91 20 23
37 24 54 4z"/>
<path d="M3097 3893 c-4 -3 -7 -17 -7 -30 0 -12 -6 -23 -13 -23 -30 0 -38 -51
-36 -215 1 -89 -1 -167 -4 -173 -10 -15 38 -42 74 -42 17 0 46 7 65 16 l34 16
0 229 0 229 -53 0 c-30 0 -57 -3 -60 -7z"/>
<path d="M3993 3110 c-38 -23 -51 -45 -57 -95 -3 -20 -7 -40 -10 -43 -8 -8 32
-35 79 -52 22 -9 44 -25 50 -38 5 -12 13 -22 18 -22 4 0 5 -4 2 -10 -11 -17
-61 -11 -90 12 l-27 21 -19 -24 c-26 -35 -29 -56 -10 -78 29 -34 63 -41 118
-27 65 17 88 30 112 65 46 64 12 156 -66 179 -22 7 -48 21 -58 32 -21 23 -20
24 65 24 48 1 55 4 58 23 7 50 -101 72 -165 33z"/>
<path d="M4360 3121 c-11 -7 -16 -47 -22 -167 -7 -136 -6 -161 8 -182 12 -18
21 -22 37 -17 12 4 51 9 87 11 100 7 112 11 108 37 -3 20 -9 22 -81 25 -83 3
-95 11 -83 55 5 21 14 25 73 33 59 8 68 12 71 31 3 19 -2 22 -65 28 -93 9 -93
9 -84 42 7 27 11 28 86 35 81 8 97 17 93 53 -3 18 -11 20 -108 23 -58 1 -112
-2 -120 -7z"/>
<path d="M4784 3121 c-26 -11 -34 -65 -34 -222 0 -112 8 -135 47 -127 14 2 19
16 23 57 3 30 10 56 17 58 7 3 26 -21 44 -52 35 -62 63 -81 103 -71 34 8 33
21 -10 84 l-35 53 30 28 c54 50 50 130 -9 172 -29 21 -144 34 -176 20z m124
-80 c8 -5 12 -21 10 -37 -3 -26 -7 -29 -45 -32 -43 -3 -43 -3 -43 31 0 18 3
37 7 40 9 10 55 8 71 -2z"/>
<path d="M5618 3123 c-13 -3 -18 -20 -23 -71 -3 -37 -10 -96 -15 -132 -8 -52
-7 -67 5 -80 8 -9 15 -28 15 -43 0 -33 11 -40 44 -27 l26 10 0 164 c0 148 -2
166 -17 174 -10 5 -26 7 -35 5z"/>
<path d="M5940 3117 c-95 -32 -125 -72 -139 -184 -9 -77 -4 -99 34 -138 40
-39 68 -47 135 -36 80 13 140 46 140 78 0 16 -30 17 -81 2 -98 -30 -170 67
-123 166 13 28 24 35 65 45 43 11 54 10 88 -7 31 -15 42 -17 55 -8 9 7 16 17
16 22 0 16 -43 50 -79 62 -41 13 -65 13 -111 -2z"/>
<path d="M5173 3018 c3 -90 7 -110 32 -162 20 -40 42 -66 67 -83 49 -30 69
-21 93 44 10 26 28 71 40 98 36 80 65 164 65 186 0 16 -5 20 -27 17 -24 -3
-35 -16 -78 -100 -27 -54 -52 -95 -56 -90 -3 4 -18 39 -33 77 -37 94 -52 115
-81 115 l-25 0 3 -102z"/>
<path d="M6281 3083 c-16 -56 -14 -298 2 -310 20 -17 81 -24 113 -14 16 5 50
12 77 16 37 5 47 10 47 25 0 23 -20 29 -105 32 l-60 3 0 35 c0 34 1 35 50 42
85 14 95 17 95 37 0 14 -10 20 -46 25 -26 3 -60 6 -76 6 -27 0 -29 3 -26 33
l3 32 71 5 c74 5 104 20 104 51 0 18 -9 19 -120 19 l-119 0 -10 -37z"/>
</g>
</svg>




		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
